import React, { Component } from 'react';
import { Card } from '@material-ui/core';

class Contact extends Component {
    render() {
        return (
            <div>
                <Card>
                    hi
                </Card>
                <Card>
                    hi
                </Card>
                <Card>
                    hi
                </Card>
                <Card>
                    hi
                </Card>
            </div>
        );
    } 
}

export default Contact;  